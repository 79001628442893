import { Component, OnInit } from '@angular/core';
import { AppState, getUser, getUserSuccess } from '@core';
import { AppType, AuthenticationModel, User, UserMeta } from '@domains';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  LocalStorageService,
  UploadFileService,
  UsersService,
} from '@rspl-api';
import { AuthenticationService } from '@rspl-auth';
import { TableService } from '@rspl-table';
import { Destructible } from '@rspl-ui';
import { VersionCheckService } from '@rspl-version';
import { environment } from '@tpl/../environments/environment';
import * as moment from 'moment';
import { filter, take } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends Destructible implements OnInit {
  versionValid?: boolean;
  title = 'resupply-unified-portal';
  isTPL = environment.app === AppType.TPL;
  isZendesk = environment.app === AppType.ZENDESK;
  loaded?: boolean;
  authData?: AuthenticationModel;

  constructor(
    private versionCheck: VersionCheckService,
    private store: Store<AppState>,
    private actions: Actions,
    private userService: UsersService,
    private uploadService: UploadFileService,
    private authService: AuthenticationService,
    private tableService: TableService,
    private localStorage: LocalStorageService
  ) {
    super();
    moment.updateLocale('en', {
      week: {
        dow: 1,
        doy: 1,
      },
    });
  }

  ngOnInit(): void {
    this.versionCheck
      .initVersionCheck()
      .pipe(take(1))
      .subscribe(() => {
        this.versionValid = true;
        if (environment.app !== AppType.TAX_RECEIPT) {
          this.init();
        } else {
          this.loaded = true;
        }
      });
  }

  private init(): void {
    this.authService.authData$
      .pipe(
        filter((res) => !res),
        take(1)
      )
      .subscribe(() => {
        this.loaded = true;
      });
    this.authService.authData$
      .pipe(
        filter((res) => !!res),
        take(1)
      )
      .subscribe((auth) => {
        this.authData = auth;
        this.loaded = false;
        if (auth?.meta?.tableConfiguration?.includes('https://')) {
          this.getTableConfig(auth.meta);
        } else if (auth.meta?.onboardingCompleted) {
          this.store.dispatch(getUser({ id: auth.id }));
          this.actions
            .pipe(ofType(getUserSuccess), take(1))
            .subscribe((payload) => {
              const user = new User(payload.user, environment.app);
              if (user?.meta?.tableConfiguration?.includes('json')) {
                this.getTableConfig(user.meta);
              } else {
                const file: File = new File(
                  [new Blob([JSON.stringify({})])],
                  `user-${auth.id}${this.isZendesk ? '-zd' : ''}-config.json`
                );
                this.uploadService
                  .uploadFile(file, true)
                  .pipe(take(1))
                  .subscribe({
                    next: (uploadResponse) => {
                      const meta = new UserMeta(
                        {
                          ...auth.meta,
                          onboardingCompleted:
                            auth.meta?.onboardingCompleted !== false,
                        },
                        environment.app
                      );
                      meta.tableConfiguration = uploadResponse.url;
                      this.userService
                        .updateUserMeta(auth.id, meta)
                        .pipe(take(1))
                        .subscribe({
                          next: (user) => this.setLoaded(user.meta),
                          error: () => (this.loaded = true),
                        });
                    },
                    error: () => (this.loaded = true),
                  });
              }
            });
        } else {
          this.setLoaded(this.authData?.meta);
        }
      });
  }

  private setLoaded(meta?: UserMeta): void {
    const user = {
      ...(this.authData || {}),
      ...JSON.parse(this.localStorage.getItem('rspl-user') || '{}'),
    };
    user.meta = meta;
    this.localStorage.setItem('rspl-user', JSON.stringify(user));
    this.loaded = true;
  }

  private getTableConfig(meta: UserMeta): void {
    if (meta.tableConfiguration) {
      this.userService
        .getConfig(meta.tableConfiguration)
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this.tableService.loadConfigurations(data);
            this.setLoaded(meta);
          },
          error: () => (this.loaded = true),
        });
    } else {
      this.setLoaded(meta);
    }
  }
}
