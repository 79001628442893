import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UiTemplateComponent } from '@core';
import { Role } from '@domains';
import { AuthGuard, OnboardingGuard, RoleGuard } from '@rspl-auth';
import { HealthCheckComponent } from '@rspl-ui';

const routes: Routes = [
  {
    path: '',
    component: UiTemplateComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard, OnboardingGuard, RoleGuard],
        data: { roles: [Role.PARTNER] },
        loadChildren: () =>
          import('@donations').then((module) => module.DonationsRootModule),
      },
      {
        path: 'trucks',
        canActivate: [AuthGuard, OnboardingGuard, RoleGuard],
        data: { roles: [Role.PARTNER] },
        loadChildren: () =>
          import('@trucks').then((module) => module.TrucksRootModule),
      },
      {
        path: 'onboarding',
        loadChildren: () =>
          import('@onboarding').then((module) => module.OnboardingModule),
      },
      {
        path: 'users',
        canActivate: [AuthGuard, OnboardingGuard, RoleGuard],
        data: { roles: [Role.PARTNER] },
        loadChildren: () =>
          import('@users').then((module) => module.UsersRootModule),
      },
      {
        path: 'settings',
        canActivate: [AuthGuard, OnboardingGuard, RoleGuard],
        data: { roles: [Role.PARTNER] },
        loadChildren: () =>
          import('@partners').then((module) => module.PartnersRootModule),
      },
      {
        path: 'users',
        canActivate: [AuthGuard, OnboardingGuard, RoleGuard],
        data: { roles: [Role.PARTNER] },
        loadChildren: () =>
          import('@users').then((module) => module.UsersRootModule),
      },
      {
        path: 'journeys',
        loadChildren: () =>
          import('@journeys').then((module) => module.JourneysModule),
      },
    ],
  },
  {
    path: 'hc',
    component: HealthCheckComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
